var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.details,"page":_vm.pageNo,"items-per-page":_vm.recordCount,"loading":_vm.loading,"show-select":"","hide-default-footer":"","disable-sort":"","dense":""},scopedSlots:_vm._u([{key:"header.action",fn:function(){return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mx-1",attrs:{"disabled":!_vm.isDraft && !_vm.isToPay,"icon":""},on:{"click":_vm.create}},on),[_c('v-icon',{attrs:{"medium":""}},[_vm._v("mdi-plus")])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t("text.create-record")))])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mr-1",attrs:{"disabled":!_vm.isDraft && !_vm.isToPay,"icon":""},on:{"click":_vm.move}},on),[_c('v-icon',{attrs:{"medium":""}},[_vm._v("mdi-export")])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t("text.move-record")))])])]},proxy:true},{key:"item.weight",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(value.toFixed(2))+" ")]}},{key:"item.totalWeight",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(value.toFixed(2))+" ")]}},{key:"item.price",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(value.toFixed(2))+" ")]}},{key:"item.totalAmount",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(value.toFixed(2))+" ")]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"ml-1",attrs:{"icon":""},on:{"click":function($event){return _vm.modify(item)}}},on),[_c('v-icon',{attrs:{"medium":""}},[_vm._v("mdi-pencil")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("text.modify")))])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mx-1",attrs:{"disabled":!_vm.isDraft && !_vm.isToPay,"icon":""},on:{"click":function($event){return _vm.removeDialog(item)}}},on),[_c('v-icon',{attrs:{"medium":""}},[_vm._v("mdi-trash-can")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("text.delete")))])])]}}]),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})],1)],1),_c('v-divider',{staticClass:"mt-2 mb-4"}),_c('v-row',[_c('v-col',{staticClass:"pt-1",attrs:{"sm":"3","md":"2","lg":"2","xl":"1"}},[_c('v-select',{staticClass:"mt-4 text-center",attrs:{"label":_vm.$t('text.record-count'),"items":_vm.recordCounts,"dense":""},on:{"input":function($event){_vm.pageNo = 1; _vm.load();}},model:{value:(_vm.recordCount),callback:function ($$v) {_vm.recordCount=$$v},expression:"recordCount"}})],1),_c('v-col',[_c('v-pagination',{attrs:{"length":_vm.pageCount,"total-visible":"12"},on:{"input":function($event){return _vm.load()}},model:{value:(_vm.pageNo),callback:function ($$v) {_vm.pageNo=$$v},expression:"pageNo"}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }