





















































































































import { Vue, Component, Prop } from "vue-property-decorator";
import { CustomerOrderInfoProperties } from "./CustomerOrderInfoView.vue";
import { AlertDialogProperties } from "@/components/shared/AlertDialogView.vue";
import { CustomerOrderDetailDialogProperties } from "./CustomerOrderDetailDialogView.vue";
import { CustomerOrderDetailMoveProperties } from "./CustomerOrderDetailMoveView.vue";
import ExceptionUtil from "@/utilities/ExceptionUtil";
import CommonUtil from "@/utilities/CommonUtil";
import CustomerOrderDetailService from "@/services/CustomerOrderDetailService";

@Component({
    data: () => ({
        recordCounts: [10, 20, 30, 40, 50],
        pageNo: 1,
        pageCount: 1,
        recordCount: 10,
        selected: [],
        details: [],
        loading: false,
    }),
})
export default class CustomerOrderDetailView extends Vue {
    @Prop() private properties: CustomerOrderInfoProperties;
    @Prop() private alertDialog: AlertDialogProperties;
    @Prop() private customerOrderDetailDialog: CustomerOrderDetailDialogProperties;
    @Prop() private customerOrderDetailMove: CustomerOrderDetailMoveProperties;
    private customerOrderDetailService = new CustomerOrderDetailService();

    public get headers() {
        return [
            {
                text: this.$t("text.line-no"),
                value: "lineNo",
                width: "10px",
            },
            {
                text: this.$t("text.product-code"),
                value: "productCode",
            },
            {
                text: this.$t("text.product-name"),
                value: "productName",
            },
            {
                text: this.$t("text.quantity"),
                value: "quantity",
                align: "right",
            },
            {
                text: this.$t("text.weight-g"),
                value: "weight",
                align: "right",
            },
            {
                text: this.$t("text.total-weight-g"),
                value: "totalWeight",
                align: "right",
            },
            {
                text: this.$t("text.price"),
                value: "price",
                align: "right",
            },
            {
                text: this.$t("text.total-amount"),
                value: "totalAmount",
                align: "right",
            },
            {
                text: this.$t("text.actions"),
                value: "action",
                fixed: true,
                align: "center",
                width: "120px",
                sortable: false,
            },
        ];
    }

    public get customerOrder() {
        return this.properties.customerOrder;
    }

    public get isDraft() {
        return this.customerOrder.status === "Draft";
    }

    public get isToPay() {
        return this.customerOrder.status === "To-Pay";
    }

    public created() {
        this.load();

        this.properties.events.subscribe("load-details", this.load);
        this.properties.events.subscribe("alert-dialog-clicked", this.alertDialogClicked);
        this.customerOrderDetailMove.events.subscribe("moved", this.moved);
    }

    public destroyed() {
        this.properties.events.remove("load-details", this.load);
        this.properties.events.remove("alert-dialog-clicked", this.alertDialogClicked);
        this.customerOrderDetailMove.events.remove("moved", this.moved);
    }

    public async load() {
        const pageNo = this.$data.pageNo;
        const rowCount = this.$data.recordCount;
        const rowStart = (pageNo - 1) * rowCount;

        try {
            const customerOrder = this.properties.customerOrder;
            if (customerOrder.id) {
                const r = await this.customerOrderDetailService.get({
                    rowStart: rowStart,
                    rowCount: rowCount,
                    countRecord: true,
                    customerOrderId: customerOrder.id,
                });
                const details: any[] = r.data.customerOrderDetails;

				details.forEach(e => {
					e.weight ??= 0;
					e.totalWeight ??= 0;
					e.price ??= 0;
					e.totalAmount ??= 0;
				});

                details.forEach((e, i) => (e.lineNo = rowStart + 1 + i));
                this.$data.details = details;
            } else {
                this.$data.details = [];
            }
        } catch (e) {
            if (!(await ExceptionUtil.isAuthError(this.$router, e))) {
                var m = ExceptionUtil.getMessage(e);
                AlertDialogProperties.error(this.alertDialog, null, m);
                this.alertDialog.visible = true;
            }
        }
    }

    public create() {
        this.customerOrderDetailDialog.isNew = true;
        this.customerOrderDetailDialog.detail = {
            comment: {},
            quantity: 0,
            weight: 0,
            totalWeight: 0,
            price: 0,
            totalAmount: 0,
        };
        this.customerOrderDetailDialog.visible = true;
    }

    public move() {
        if (this.$data.selected && this.$data.selected.length !== 0) {
            const customerOrder = this.properties.customerOrder;
            this.customerOrderDetailMove.customerOrder = customerOrder;
            this.customerOrderDetailMove.selected = this.$data.selected;
            this.customerOrderDetailMove.visible = true;
        } else {
            var m: any = this.$t("message.nothing-selected");
            AlertDialogProperties.info(this.alertDialog, null, m);
            this.alertDialog.visible = true;
        }
    }

    public async moved() {
        await this.properties.events.fire("load-header");
    }

    public modify(detail: any) {
        this.customerOrderDetailDialog.isNew = false;
        this.customerOrderDetailDialog.detail = CommonUtil.clone(detail);
        this.customerOrderDetailDialog.visible = true;
    }

    public async remove(detail: any) {
        try {
            await this.customerOrderDetailService.delete(detail);
            await this.properties.events.fire("load-header");
        } catch (e) {
            if (!(await ExceptionUtil.isAuthError(this.$router, e))) {
                var m = ExceptionUtil.getMessage(e);
                AlertDialogProperties.error(this.alertDialog, null, m);
                this.alertDialog.visible = true;
            }
        }
    }

    public removeDialog(detail: any) {
        var m = "No: " + detail.lineNo;

        AlertDialogProperties.delete(this.alertDialog, m);
        this.alertDialog.tag = { command: "remove-detail", detail };
        this.alertDialog.visible = true;
    }

    public alertDialogClicked(button: string) {
        const tag = this.alertDialog.tag;
        if (tag) {
            if (tag.command === "remove-detail" && button === this.$t("text.yes")) {
                this.remove(tag.detail);
            }
        }
    }
}
